<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "VouchersRouter",
});
</script>

<style scoped></style>